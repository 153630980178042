'use client'

import { db } from "@/connectors/firebase";
import { ChatConnection, Connection } from "@/interfaces/firebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import useAuth from "@/hooks/useAuth";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getUser } from "@/services/users";

const ConnectionsContext = React.createContext({
    connections: [] as ChatConnection[],
    isLoadingConnections: true as boolean,
});

export function useConnections() {
    return useContext(ConnectionsContext);
}

export function ConnectionsProvider({ children }: { children: React.ReactNode }) {
    const { currentUser, userLoggedIn } = useAuth()
    const queryClient = useQueryClient()
    const [isLoadingConnections, setIsLoadingConnections] = useState<boolean>(true)
    const { data } = useQuery(
        {
            queryKey: ["connections"],
            initialData: () => {
                return queryClient.getQueryData(["connections"]) as ChatConnection[] ?? []
            },
            staleTime: Infinity
        }
    )
    const [connections, setConnections] = useState<ChatConnection[]>(data)

    useEffect(() => {
        if (data) setConnections(data)
    }, [])

    useEffect(() => {
        if (connections) queryClient.setQueryData(["connections"], connections);
    }, [connections])

    useEffect(() => {
        if (!userLoggedIn) return
        let unsub = () => { };
        try {
            const connectionsRef = query(
                collection(db, "connections"),
                where("members", "array-contains", currentUser.id),
            )
            unsub = onSnapshot(connectionsRef, async (querySnapshot) => {
                let allConnections: (ChatConnection)[] = []
                for (const snap of querySnapshot.docs) {
                    let data = snap.data() as Connection;
                    let sender = data.members?.find(r => r !== currentUser.id)
                    if (sender) {
                        const user = await getUser(sender)
                        const connection: ChatConnection = {
                            profile: user,
                            connectionId: snap.id,
                            connection: data
                        }
                        allConnections.push(connection)
                    }
                }
                setConnections(allConnections)
                setIsLoadingConnections(false)
            });
        } catch (error: any) {
            console.log(error)
        }
        return unsub
    }, [currentUser.id, userLoggedIn])

    const value = {
        connections,
        isLoadingConnections
    }

    return (
        <ConnectionsContext.Provider value={value}>
            {children}
        </ConnectionsContext.Provider>
    )
}