"use client";

import { useQuery, useQueryClient } from "@tanstack/react-query";

type AppVersion = {
  version: string
}

type Semver = {
  version: {
    major: number,
    minor: number,
    patch: number
  }
}


const parseVersion = (version: string): Semver => {
  const splitten = version.split(".");
  return {
    version: {
      major: splitten.length > 0 ? Number(splitten[0]) : Number(version),
      minor: splitten.length > 1 ? Number(splitten[1]) : 0,
      patch: splitten.length > 2 ? Number(splitten[2]) : 0,
    }
  }
}

const compareVersions = (previousVersion: Semver, currentVersion: Semver) => {

  if (currentVersion.version.major > previousVersion.version.major) {
    return "major"
  }

  if (currentVersion.version.major === previousVersion.version.major &&
    currentVersion.version.minor > previousVersion.version.minor
  ) {
    return "minor"
  }

  if (currentVersion.version.major === previousVersion.version.major &&
    currentVersion.version.minor === previousVersion.version.minor &&
    currentVersion.version.patch > previousVersion.version.patch
  ) {
    return "patch"
  }

  return "same"

}

export const useVersionManager = () => {
  const queryClient = useQueryClient();

  const appVersion = useQuery({
    staleTime: Infinity,
    refetchInterval: false,
    queryKey: ["appVersion"],
    refetchOnMount: true,
    queryFn: async () => {
      const request = await fetch(`version.json?now=${new Date().getTime()}`);
      const result = await request.json() as AppVersion;

      return parseVersion(result.version)
    },
  });

  const currentVersion = useQuery({
    staleTime: 1000 * 30,
    refetchInterval: 1000 * 30,
    queryKey: ["currentVersion"],
    refetchOnMount: "always",
    refetchOnReconnect: true,
    enabled: appVersion.data !== undefined,
    queryFn: async () => {

      const request = await fetch(`version.json?now=${new Date().getTime()}`);
      const result = await request.json() as { version: string };

      const currentVersion = parseVersion(result.version);

      const cachedVersion = queryClient.getQueryState<Semver>(["appVersion"]);
      if (cachedVersion?.data) {

        const versionDifference = compareVersions(cachedVersion.data, currentVersion)
        if (versionDifference === "major") {
          console.log("major difference, clear storage...")
          try {
            const indexedDatabases = await window.indexedDB.databases();
            for (const db of indexedDatabases) {
              if (db.name)
                window.indexedDB.deleteDatabase(db.name);
            }
          }
          catch (ex) { }

          try {
            window.localStorage.clear();
            window.sessionStorage.clear();
          }
          catch (ex) { }
        }

        if (versionDifference !== "same") {
          queryClient.setQueryData(["appVersion"], currentVersion)
          setTimeout(() => window.location.reload(), 1000)
        }
      }

      return currentVersion;
    }

  });

  return currentVersion;

};
