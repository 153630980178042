"use client"

import React, { createContext, useContext, useState, ReactNode, useEffect, useCallback } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import useAuth from "@/hooks/useAuth";
import { Connection, Conversation, IMessage, Leads, Need } from '@/interfaces/firebase';
import { useConnections } from './connectionsContext';
import { chatsProps, useMessages } from './messagesContext';
import { useNeeds } from './needsContext';
import { useLeads } from './LeadsContext';
import { useQueryClient } from '@tanstack/react-query';


interface AllNotificationContextProps {
    allNotifications: number;
    messageNotifications: number;
    connectionNotifications: number;
    needsNotifications: number;
}

const AllNotificationContext = createContext<AllNotificationContextProps | undefined>(undefined);

export const useAllNotifications = () => {
    const context = useContext(AllNotificationContext);
    if (!context) {
        throw new Error('useAllNotifications must be used within a AllNotificationProvider');
    }
    return context;
};

export const AllNotificationProvider = ({ children }: { children: ReactNode }) => {
    const [isLoadFirstTime, setIsLoadFirstTime] = useState<boolean>(false);
    const [allNotifications, setAllNotifications] = useState<number>(0);
    const [messageNotifications, setMessageNotifications] = useState<number>(0);
    const [connectionNotifications, setConnectionNotifications] = useState<number>(0);
    const [needsNotifications, setNeedsNotifications] = useState<number>(0);
    const [messagesToCompare, setMessagesToCompare] = useState<IMessage[]>([]);
    const path = usePathname();
    const searchParams = useSearchParams();
    const { currentUser, userLoggedIn } = useAuth()
    const { messages, isLoadingMessages } = useMessages()
    const { connections } = useConnections()
    const { needs } = useNeeds()
    const { leads } = useLeads()
    const queryClient = useQueryClient();

    useEffect(() => {
        if (userLoggedIn) {
            setIsLoadFirstTime(false)
            setAllNotifications(0)
            setMessageNotifications(0)
            setConnectionNotifications(0)
            setNeedsNotifications(0)
        }
    }, [userLoggedIn])

    useEffect(() => {
        if (!connections.length) return
        let allConnections: Connection[] = []
        for (const connection of connections) {
            allConnections.push(connection.connection)
        }
        const pending = allConnections.filter(f => f.status === "pending" && f.members[0] !== currentUser.id).length
        if (pending) {
            setConnectionNotifications(pending)
        }
        else {
            setConnectionNotifications(0)
        }
    }, [connections])

    useEffect(() => {
        try {
            if (!needs.length) return
            const onlyNeedUserLogged: Need[] = []
            for (const need of needs) {
                if (need.creator == currentUser.id) onlyNeedUserLogged.push(need)
            }
            if (!onlyNeedUserLogged.length) return
            let onlyLeadsReceived: Leads[] = []
            for (const need of onlyNeedUserLogged) {
                let getAllLeads = leads.filter(f => f.needId === need.id)
                if (getAllLeads.length) onlyLeadsReceived = getAllLeads
            }
            let IsNotWasSeen: number = 0
            for (const lead of onlyLeadsReceived) {
                if (!lead.wasSeen) IsNotWasSeen++;
            }
            if (IsNotWasSeen) setNeedsNotifications(IsNotWasSeen)
        } catch (error) {
            console.log(error)
        }
    }, [needs, leads])

    useEffect(() => {
        setAllNotifications(messageNotifications + connectionNotifications + needsNotifications)
    }, [messageNotifications, connectionNotifications, needsNotifications])

    // useEffect(() => {
    //     getAllNotificationMessage()
    // }, [messages, isLoadingMessages])

    useEffect(() => {
        if (path.includes("messages")) {
            setMessageNotifications(0)
            let allMessages: IMessage[] = getAllMessagesFromCache()
            if (isLoadingMessages || !allMessages.length) return
            const userMessagesOnly = filterUserContextChats(allMessages)
            // const messagesOtherUserOnly = filterUserMessages(userMessagesOnly)
            setMessagesToCompare(userMessagesOnly)
        }
    }, [path])

    useEffect(() => {
        let allMessages: IMessage[] = getAllMessagesFromCache()
        console.log("NOTIFICACAO MENSSAGES ", allMessages)
        getAllNotificationMessage(allMessages)
    }, [queryClient, messages]);

    const getAllMessagesFromCache = (): IMessage[] => {
        const cachedConversations = queryClient.getQueryData(["chats"]) as chatsProps
        if (!cachedConversations) return []
        let allMessages: IMessage[] = []
        for (const conversation of Object.values(cachedConversations)) {
            const cachedMessages = queryClient.getQueryData<Conversation>([
                "chats",
                conversation.id,
            ]);
            if (cachedMessages?.messages) allMessages.push(...cachedMessages.messages)
        }
        return allMessages
    }

    const getAllNotificationMessage = async (allMessages: IMessage[]) => {
        try {
            // await waitForSeconds(5)
            console.log("NOTIFICACAO MENSSAGES 1")
            if (isLoadingMessages || !allMessages.length) return
            const userMessagesOnly = filterUserContextChats(allMessages)
            console.log("NOTIFICACAO MENSSAGES 2")
            if (!messagesToCompare || !isLoadFirstTime) {
                setMessagesToCompare(userMessagesOnly)
                setIsLoadFirstTime(true)
                console.log("NOTIFICACAO MENSSAGES 3")
                return
            }
            let newMessage: number = 0
            console.log("NOTIFICACAO MENSSAGES 4")
            if ((userMessagesOnly?.length || 0) > (messagesToCompare?.length || 0)) {
                console.log("NOTIFICACAO MENSSAGES 5")
                let chatId = searchParams.get(`id`);
                let stayInChat = allMessages.find(f => f.conversation == chatId)
                if (!path.includes("messages") && (!path.includes(`chat`) && (!chatId && !stayInChat))) {
                    newMessage++
                }
            }
            setMessageNotifications((prev) => (newMessage > prev ? newMessage : prev))
        } catch (error) {
            console.log(error)
        }
    }

    const filterUserContextChats = (allMessages: IMessage[]): IMessage[] => {
        return allMessages.reduce((acumulateMessages, currentMessage) => {
            if (currentMessage.from !== currentUser.id && currentMessage.from !== "RAI") {
                acumulateMessages.push(currentMessage)
            }
            return acumulateMessages;
        }, [] as IMessage[]);
    }

    // const filterUserMessages = (allMessages: IMessage[]): IMessage[] => {
    //     return allMessages.reduce((acumulateMessages, currentMessage) => {
    //         const chat = chats[chatId];
    //         if (chat.messages && chat.messages.length) {
    //             filteredChats[chatId] = filteredChats[chatId] || { ...chat, messages: [] };
    //             filteredChats[chatId].messages = chat.messages.filter(f => {
    //                 if (f.from !== currentUser.id) {
    //                     if (f.from === "RAI" && typeof f.data.content === "string" && f.data.role === "assistant") {
    //                         return true;
    //                     }
    //                     if (f.from === "RAI") {
    //                         return false;
    //                     }
    //                     return true;
    //                 }
    //                 return false;
    //             });
    //         }
    //         return filteredChats;
    //     }, [] as IMessage[]);
    // };

    const waitForSeconds = (seconds: number): Promise<void> => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, seconds * 1000);
        });
    };

    return (
        <AllNotificationContext.Provider value={{ allNotifications, messageNotifications, connectionNotifications, needsNotifications }}>
            {children}
        </AllNotificationContext.Provider>
    );
};
