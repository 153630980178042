'use client'

import { db } from "@/connectors/firebase";
import { SpeedNetwork, SpeedNetworkRecording, SpeedNetworkRoom } from "@/interfaces/firebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import useAuth from "@/hooks/useAuth";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { cloneDeep } from "lodash";

const SpeedNetworkContext = React.createContext({
    speedNetwork: [] as SpeedNetwork[],
});

export function useSpeedNetwork() {
    return useContext(SpeedNetworkContext);
}

export function SpeedNetworkProvider({ children }: { children: React.ReactNode }) {
    const { currentUser, userLoggedIn } = useAuth()
    const queryClient = useQueryClient()
    const { data } = useQuery(
        {
            queryKey: ["speedNetwork"],
            initialData: () => {
                return queryClient.getQueryData(["speedNetwork"]) as SpeedNetwork[] ?? []
            },
            staleTime: Infinity
        }
    )
    const [speedNetwork, setSpeedNetwork] = useState<SpeedNetwork[]>(data)

    useEffect(() => {
        if (data) setSpeedNetwork(data)
    }, [])

    useEffect(() => {
        if (speedNetwork) queryClient.setQueryData(["speedNetwork"], speedNetwork);
    }, [speedNetwork])

    useEffect(() => {
        if (!userLoggedIn) return
        let unsub = () => { }
        try {
            const speedNetworkRef = query(
                collection(db, "speedNetwork"),
                where("participants", "array-contains", currentUser.id)
            )
            unsub = onSnapshot(speedNetworkRef, async (speedNetworkSnap) => {
                if (speedNetworkSnap.size == 0) {
                    return
                }
                let speedNetworkList: SpeedNetwork[] = []
                const cachedSpeedNetwork = JSON.parse(JSON.stringify(data))
                speedNetworkSnap.forEach((doc) => {
                    speedNetworkList.push(doc.data() as SpeedNetwork)
                })

                let allSpeedNetwork: SpeedNetwork[] = []

                speedNetworkList.forEach((speed, key) => {
                    let checkSpeedNetwork = cloneDeep(cachedSpeedNetwork[key])
                    if (checkSpeedNetwork && checkSpeedNetwork.recording) delete checkSpeedNetwork.recording
                    if (checkSpeedNetwork && checkSpeedNetwork.room) delete checkSpeedNetwork.room
                    let currentSpeedNetwork = speed
                    if (currentSpeedNetwork && currentSpeedNetwork.recording) delete currentSpeedNetwork.recording
                    if (currentSpeedNetwork && currentSpeedNetwork.room) delete currentSpeedNetwork.room
                    if (JSON.stringify(currentSpeedNetwork) == JSON.stringify(checkSpeedNetwork)) {
                        return
                    }
                    allSpeedNetwork[key] = { ...speed, recording: (cachedSpeedNetwork[key] && cachedSpeedNetwork[key].recording) || [], room: (cachedSpeedNetwork[key] && cachedSpeedNetwork[key].room) || [] }
                })

                setSpeedNetwork(allSpeedNetwork)

                speedNetworkList.forEach((speed) => {
                    if (!speed) return
                    const speedNetworkRecordingQuery = query(
                        collection(db, "speedNetwork", speed.id, "recording")
                    );
                    // recording subcollections
                    onSnapshot(speedNetworkRecordingQuery, (speedNetworkRecordingSnap) => {
                        let allspeedNetowrkRecording: SpeedNetworkRecording[] = []
                        speedNetworkRecordingSnap.forEach((speedNetworkDoc) => {
                            allspeedNetowrkRecording.push({ ...speedNetworkDoc.data() as SpeedNetworkRecording, id: speedNetworkDoc.id })
                        })
                        setSpeedNetwork((prev) => {
                            const index = prev.findIndex((item) => item.id === speed.id);
                            if (index !== -1) {
                                return prev.map((item, i) =>
                                    i === index ? { ...item, recording: allspeedNetowrkRecording } : item
                                );
                            } else {
                                return [...prev, { ...speed, recording: allspeedNetowrkRecording }];
                            }
                        })
                    })
                    const speedNetworkRoomQuery = query(
                        collection(db, "speedNetwork", speed.id, "room")
                    );
                    // room subcollections
                    onSnapshot(speedNetworkRoomQuery, (speedNetworkRoomSnap) => {
                        let allSpeedNetworkRoom: SpeedNetworkRoom[] = []
                        speedNetworkRoomSnap.forEach((speedNetworkDoc) => {
                            allSpeedNetworkRoom.push({ ...speedNetworkDoc.data() as SpeedNetworkRoom, id: speedNetworkDoc.id })
                        })
                        setSpeedNetwork((prev) => {
                            const index = prev.findIndex((item) => item.id === speed.id);
                            if (index !== -1) {
                                return prev.map((item, i) =>
                                    i === index ? { ...item, room: allSpeedNetworkRoom } : item
                                );
                            } else {
                                return [...prev, { ...speed, room: allSpeedNetworkRoom }];
                            }
                        })
                    })
                })
            })
        } catch (error: any) {
            console.log(error) //when have an error in firebase (for example invalid id)
        }
        return unsub
    }, [currentUser.id, userLoggedIn])

    const value = {
        speedNetwork
    }

    return (
        <SpeedNetworkContext.Provider value={value}>
            {children}
        </SpeedNetworkContext.Provider>
    )
}