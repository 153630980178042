import { db } from "@/connectors/firebase";
import { Conversation, IMessage } from "@/interfaces/firebase";
import { collection, onSnapshot, query, where, or } from "firebase/firestore";
import React, { useContext, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useAuth from "@/hooks/useAuth";

export interface chatsProps {
    [x: string]: Conversation;
}

const MessagesContext = React.createContext({
    messages: {} as chatsProps,
    isLoadingMessages: true as boolean,
});

export function useMessages() {
    return useContext(MessagesContext);
}

export function MessagesProvider({ children }: { children: React.ReactNode }) {
    const { currentUser, userLoggedIn } = useAuth();
    const queryClient = useQueryClient();

    const { data: initialMessages, isLoading: isLoadingMessages } = useQuery({
        queryKey: ["chats"],
        queryFn: async () => {
            return queryClient.getQueryData(["chats"]) as chatsProps || {};
        },
        staleTime: Infinity,
    });

    useEffect(() => {
        if (initialMessages) {
            queryClient.setQueryData(["chats"], initialMessages);
        }
    }, [initialMessages, queryClient]);

    useEffect(() => {
        if (!userLoggedIn || isLoadingMessages) return;

        const unsubConversations = onSnapshot(
            query(
                collection(db, "conversations"),
                or(
                    where("participants", "array-contains", currentUser.id),
                    where("isBulletinBoard", "==", true)
                )
            ),
            (snapshot) => {
                const conversations: chatsProps = {};
                snapshot.forEach((doc) => {
                    const conversationData = doc.data() as Conversation;
                    conversations[doc.id] = {
                        ...conversationData,
                        id: doc.id,
                        messages: initialMessages?.[doc.id]?.messages || []
                    };
                });

                queryClient.setQueryData(["chats"], conversations);

                for (const conversationId in conversations) {
                    const messagesRef = query(
                        collection(db, "conversations", conversationId, "messages")
                    );

                    onSnapshot(messagesRef, (messagesSnap) => {
                        const allMessages: IMessage[] = messagesSnap.docs.map((doc) => ({
                            ...doc.data(),
                            id: doc.id,
                        })) as IMessage[];

                        allMessages.sort((a, b) => a.timestamp.seconds - b.timestamp.seconds);
                        console.log("carrega mensagens")
                        queryClient.setQueryData(["chats", conversationId], (prev: Conversation) => ({
                            ...prev,
                            messages: allMessages,
                        }));
                    });
                }
            }
        );

        return () => unsubConversations();
    }, [isLoadingMessages, userLoggedIn]);

    const value = {
        messages: queryClient.getQueryData(["chats"]) as chatsProps,
        isLoadingMessages
    };

    return (
        <MessagesContext.Provider value={value}>
            {children}
        </MessagesContext.Provider>
    );
}
