'use client'

import { db } from "@/connectors/firebase";
import { Event, Meeting, User } from "@/interfaces/firebase";
import { collection, doc, getDoc, onSnapshot, query, where } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import useAuth from "@/hooks/useAuth";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSpeedNetwork } from "./speedNetworkContext";
import { formatFirestoreTimestamp } from "@/functions/format";

interface eventsProps {
    [key: string]: Event
}

const EventsContext = React.createContext({
    events: {} as eventsProps,
});

export function useEvents() {
    return useContext(EventsContext);
}

export function EventsProvider({ children }: { children: React.ReactNode }) {
    const { currentUser, userLoggedIn } = useAuth()
    const queryClient = useQueryClient()
    const { speedNetwork } = useSpeedNetwork()
    const { data } = useQuery(
        {
            queryKey: ["events"],
            initialData: () => {
                return queryClient.getQueryData(["events"]) as eventsProps ?? {}
            },
            staleTime: Infinity
        }
    )
    const [events, setEvents] = useState<eventsProps>(data)

    useEffect(() => {
        if (data) setEvents(data)
    }, [])

    useEffect(() => {
        if (events) queryClient.setQueryData(["events"], events);
    }, [events])

    useEffect(() => {
        if (!userLoggedIn) return
        let unsub = () => { };
        try {
            unsub = onSnapshot(collection(db, "events"), (querySnapshot) => {
                let updatedSchedules: eventsProps = {};
                querySnapshot.forEach((doc) => {
                    const event = doc.data() as Event;
                    const eventId = doc.id
                    if (!events[eventId!] || JSON.stringify(events[eventId!]) !== JSON.stringify(data)) {
                        updatedSchedules[eventId!] = { ...event, id: eventId };
                    }
                });
                if (Object.keys(updatedSchedules).length > 0) {
                    setEvents((prev) => ({ ...prev, ...updatedSchedules }));
                }
            });
        } catch (error: any) {
            console.log(error)
        }
        return unsub
    }, [currentUser.id, userLoggedIn])

    useEffect(() => {
        try {
            if (!speedNetwork.length || !userLoggedIn) return;
            let eventArray = Object.values(events)
            if (!eventArray.length) return
            const onlySpeedNetwork = eventArray.filter(f => f.type === "speedNetwork")
            for (const event of onlySpeedNetwork) {
                const dateStartToCheck = new Date(formatFirestoreTimestamp(event.startDate, "YYYY-MM-DDTHH:mm:ss"));
                const dateEndToCheck = new Date(formatFirestoreTimestamp(event.endDate, "YYYY-MM-DDTHH:mm:ss"));

                const filteredSpeedNetwork = speedNetwork.filter((network) => {
                    const isUserParticipating = network.participants.includes(currentUser.id)

                    const startDate = new Date(formatFirestoreTimestamp(network.start, "YYYY-MM-DDTHH:mm:ss"))
                    const isWithinDateRange = (dateToCheck: Date, rangeStart: Date, rangeEnd: Date) => {
                        return dateToCheck >= rangeStart && dateToCheck <= rangeEnd;
                    };

                    const isDateMatching = isWithinDateRange(startDate, dateStartToCheck, dateEndToCheck);

                    return isUserParticipating && isDateMatching
                })
                const userList = filteredSpeedNetwork.map(m => m.participants).flat().filter(f => f !== currentUser.id)
                if (events[event.id as string].attendees?.length !== userList.length) {
                    setEvents(prev => ({
                        ...prev,
                        [event.id as string]: {
                            ...prev[event.id as string],
                            attendees: userList,
                        }
                    }))
                }
            }
        } catch (error) {
            console.log(error)
        }
    }, [events, speedNetwork])

    useEffect(() => {
        if (!userLoggedIn) return
        let unsub = () => { }
        try {
            const meetingsRef = query(
                collection(db, "meetings"),
                where("participants", "array-contains", currentUser.id)
            )
            unsub = onSnapshot(meetingsRef, async (querySnapshot) => {
                if (querySnapshot.size == 0) return
                let scheduleFromMeeting: eventsProps = {}
                for (const snap of querySnapshot.docs) {
                    const meeting = snap.data() as Meeting;
                    let title = "";
                    let descriptionEvent: string[] = [];
                    let image = "";

                    const users = meeting.participants.filter((f) => f !== currentUser.id);
                    const isGroupMeeting = meeting.participants.length === 1 || meeting.participants.length > 2;

                    if (isGroupMeeting) {
                        for (const userId of users) {
                            const userDoc = await getDoc(doc(db, "users", userId))
                            const user = userDoc.data() as User
                            descriptionEvent.push(`${user.firstName} ${user.lastName}`)
                        }
                        title = meeting.title
                    } else {
                        const userId = users[0]
                        const userDoc = await getDoc(doc(db, "users", userId))
                        const user = userDoc.data() as User
                        title = `${user.firstName} ${user.lastName}`
                        descriptionEvent.push(user.professionalRoles?.[0] || "")
                        image = user.avatarUrl || ""
                    }
                    if (meeting.status === "confirmed") {
                        const newEvent: Event = {
                            id: snap.id,
                            name: title,
                            speakers: [],
                            description: descriptionEvent.join(", "),
                            startDate: meeting.start,
                            endDate: meeting.end,
                            bannerURL: image,
                            type: "meeting",
                            attendees: meeting.participants,
                            location: meeting.location
                        };

                        if (!events[meeting.id] || JSON.stringify(events[meeting.id]) !== JSON.stringify(newEvent)) {
                            scheduleFromMeeting[meeting.id] = newEvent;
                        }
                    } else {
                        setEvents((prev) => {
                            const updatedEvents = { ...prev };
                            delete updatedEvents[meeting.id];
                            return updatedEvents;
                        });
                    }
                }
                if (Object.keys(scheduleFromMeeting).length > 0) {
                    setEvents((prev) => ({ ...prev, ...scheduleFromMeeting }));
                }
            })
        } catch (error: any) {
            console.log(error) //when have an error in firebase (for example invalid id)
        }
        return unsub
    }, [currentUser.id, userLoggedIn])

    const value = {
        events
    }

    return (
        <EventsContext.Provider value={value}>
            {children}
        </EventsContext.Provider>
    )
}