'use client'

import { auth } from "@/connectors/firebase";
import { sendMessageToAPI } from "@/services/api";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNotifications } from "./notifyContext";
import useAuth from "@/hooks/useAuth";

interface fontSizeContextProps {
	changeFontSize: (size: number) => void;
	fontSize: number;
}

const FontSizeContext = createContext<fontSizeContextProps | undefined>(undefined);

export function useFontSize() {
	const context = useContext(FontSizeContext);
	if (!context) {
		throw new Error('useFontSize must be used within a FontSizeProvider');
	}
	return context;
}

export const FontSizeProvider = ({ children }: { children: React.ReactNode }) => {
	const [fontSize, setFontSize] = useState(16)
	const { addNotification } = useNotifications()
	const { currentUser } = useAuth()

	useEffect(() => {
		document.documentElement.style.setProperty('--base-font-size', `${fontSize}px`);
	}, [fontSize]);

	useEffect(() => {
		if (currentUser.fontSize) setFontSize(parseInt(currentUser.fontSize))
	}, [currentUser.fontSize])

	const updateFontSize = async (size: number) => {
		try {
			const token = await auth.currentUser?.getIdToken(true) ?? ""
			await sendMessageToAPI({
				token: token,
				route: "profile/update",
				data: {
					id: currentUser.id,
					fontSize: size
				}
			})
		} catch (error: any) {
			addNotification({ title: "Error", body: error, type: "error" })
		}
	}

	const handleFontSize = async (size: number) => {
		setFontSize(size)
		await updateFontSize(size)
	}

	const changeFontSize = (size: number) => handleFontSize(size)

	return (
		<FontSizeContext.Provider value={{ changeFontSize, fontSize }}>
			{children}
		</FontSizeContext.Provider>
	);
};