"use client";

import React, { ErrorInfo } from 'react';
import { SourceMapConsumer } from 'source-map-js';
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from 'react-error-boundary';
import useAuth from '@/hooks/useAuth';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { db } from '@/connectors/firebase';

const decodeErrorStack = async (errorStack: string, sourceMapUrl: string): Promise<string> => {
  try {
    const response = await fetch(sourceMapUrl);
    const sourceMapData = await response.json();
    const consumer = await new SourceMapConsumer(sourceMapData);

    const decodedLines = errorStack.split('\n').map(line => {
      const match = line.match(/(.*):(\d+):(\d+)/);
      if (match) {
        const [, url, lineNumber, columnNumber] = match;
        const pos = consumer.originalPositionFor({
          line: parseInt(lineNumber, 10),
          column: parseInt(columnNumber, 10),
        });

        return `At ${pos.source}:${pos.line}:${pos.column}`;
      }
      return line;
    });

    return 'Stack Trace:' + decodedLines.join('\n');
  } catch (err) {
    console.error('Error decoding stack trace:', err);
    return ""
  }
};

const extractSourceMapUrl = (errorStack: string): string | null => {
  const match = errorStack.match(/(http[s]?:\/\/.*?\.js)/)
  return match ? match[1].replace(/\.js$/, '.js.map') : null
}

const logErrorToDatabase = async (error: Error, userId: string, errorInfo?: ErrorInfo): Promise<void> => {
  const errorStack: string = error.stack || '';
  const sourceMapUrl = extractSourceMapUrl(errorStack);
  let stackError = error.toString()
  if (sourceMapUrl) {
    stackError = await decodeErrorStack(errorStack, sourceMapUrl)
  }
  const data = {
    id: uuidv4(),
    title: "Error unexpected",
    body: stackError ? stackError : errorStack,
    path: "",
    user: userId,
    timestamp: Timestamp.now(),
    type: "error",

  }
  await addDoc(collection(db, "errorLog"), data)
};

const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {

  return (
    <div role="alert" className="flex flex-col items-center justify-center h-screen px-[30px]">
      <h2 className="text-xl font-semibold text-center text-red-600 mb-4">
        The app is undergoing maintenance.<br /><br /> Please try again in 30 mins. If this persists, contact support.
      </h2>
      <button
        onClick={resetErrorBoundary}
        className="px-4 py-2 bg-blue-500 text-white rounded-md mt-4"
      >
        Reload App
      </button>
    </div>
  );
};

const ErrorBoundary: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { currentUser } = useAuth()

  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error, errorInfo) => logErrorToDatabase(error, currentUser.id, errorInfo)}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
