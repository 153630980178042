"use client"

import React, { createContext, useContext, ReactNode, ChangeEvent, useEffect, useMemo, useState } from 'react';
import { auth } from '@/connectors/firebase';
import { usePathname } from 'next/navigation';
import useAuth from "@/hooks/useAuth";
import { sendMessageToAPI } from '@/services/api';
import { useNotifications } from './notifyContext';

interface TranslateContextProps {
    currentLanguage: string;
}

const TranslateContext = createContext<TranslateContextProps | undefined>(undefined);

export const useTranslate = () => {
    const context = useContext(TranslateContext);
    if (!context) {
        throw new Error('useTranslate must be used within a TranslateProvider');
    }
    return context;
};

export const TranslateProvider = ({ children }: { children: ReactNode }) => {
    const { currentUser } = useAuth();
    const { addNotification } = useNotifications();
    const [firstLoad, setFirstLoad] = useState(false);
    const path = usePathname();
    const id = "google_translate_element"

    const isOverviewProfile = useMemo(() => {
        if (path.includes("profile/overview-profile")) return true;
        return false;
    }, [path])

    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "en",
                autoDisplay: false
            },
            id
        )
    }

    const getGoogleTranslateCookie = (): string => {
        const name = "googtrans=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    const removeTranslate = (): void => {
        setTimeout(() => {
            document.cookie = "googtrans= /en; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT"
        }, 100);
    }

    const addLanguageToCookie = (lang: string): void => {
        setTimeout(() => {
            document.cookie = `googtrans= /en${lang}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`
        }, 100);
    }

    const updateLanguage = async (e: ChangeEvent<HTMLSelectElement>) => {
        try {
            if (!e.target.value) return
            if (e.target.value == currentUser.language) return
            if (e.target.value == "en") removeTranslate()
            const token = await auth.currentUser?.getIdToken(true) ?? ""
            await sendMessageToAPI({
                token: token,
                route: "profile/update",
                data: {
                    id: currentUser.id,
                    language: e.target.value
                }
            })
        } catch (error: any) {
            addNotification({ title: "Error", body: error, type: "error" })
        }
    }

    const scriptExists = (url: string) => {
        return document.querySelectorAll(`script[src="${url}"]`).length > 0;
    }

    const scriptDelete = (url: string): void => {
        document.querySelectorAll(`script[src="${url}"]`).forEach(e => e.parentNode?.removeChild(e))
    }

    const removeTranslateText = () => {
        const skiptranslateElements = document.querySelectorAll('.skiptranslate')
        skiptranslateElements.forEach(element => {
            const nodes = element.childNodes
            nodes.forEach(node => {
                if (node.nodeType === Node.TEXT_NODE && node.nodeValue && typeof node.nodeValue == "string") {
                    node.nodeValue = ""
                }
            })
            const span = element.querySelector('span')
            if (span) {
                span.innerHTML = ""
            }
        })
    }

    const onLanguageChange = (e: Event) => {
        const select = e.target as HTMLSelectElement;
        updateLanguage({ target: select } as ChangeEvent<HTMLSelectElement>)
    }

    const addScript = () => {
        const script = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
        scriptDelete(script)
        const selectElement = document.querySelector(`#${id} select`)
        if (selectElement) {
            selectElement.removeEventListener("change", onLanguageChange)
        }
        const addScript = document.createElement("script");
        addScript.setAttribute("src", script)

        document.body.appendChild(addScript)
        window.googleTranslateElementInit = googleTranslateElementInit

        setTimeout(() => {
            const interval = setInterval(() => {
                if (document.querySelector('.skiptranslate')) {
                    removeTranslateText();
                    clearInterval(interval);
                }
            }, 100)
        }, 500)
    }

    useEffect(() => {

        const intervalForSelect = setInterval(() => {
            const selectElement = document.querySelector(`#${id} select`) as HTMLSelectElement
            if (selectElement) {
                selectElement.addEventListener("change", onLanguageChange)
                const googleTranslateCookie = getGoogleTranslateCookie()
                let language = currentUser.language
                if (googleTranslateCookie) {
                    const langArray = googleTranslateCookie.split('/');
                    if (langArray.length === 3) {
                        language = langArray[2];
                    }
                }
                if (language) {
                    selectElement.value = language
                    selectElement.dispatchEvent(new Event('change'))
                }
                clearInterval(intervalForSelect)
            }
        }, 100)
        if (currentUser.language && currentUser.language != "en") addLanguageToCookie(`/${currentUser.language}`)
        addScript()
        setFirstLoad(true);
        return () => {
            const selectElement = document.querySelector(`#${id} select`)
            if (selectElement) {
                selectElement.removeEventListener("change", onLanguageChange)
            }
        }
    }, [])

    useEffect(() => {
        if (isOverviewProfile && firstLoad) {
            addScript()
        }
    }, [isOverviewProfile])

    return (
        <TranslateContext.Provider value={{ currentLanguage: getGoogleTranslateCookie() }}>
            {children}
        </TranslateContext.Provider>
    );
};
