'use client'

import { db } from "@/connectors/firebase";
import { Meeting, MeetingRecording, MeetingRoom } from "@/interfaces/firebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import useAuth from "@/hooks/useAuth";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { cloneDeep } from "lodash";

const MeetingsContext = React.createContext({
    meetings: [] as Meeting[],
});

export function useMeetings() {
    return useContext(MeetingsContext);
}

export function MeetingsProvider({ children }: { children: React.ReactNode }) {
    const { currentUser, userLoggedIn } = useAuth()
    const queryClient = useQueryClient()
    const { data } = useQuery(
        {
            queryKey: ["meetings"],
            initialData: () => {
                return queryClient.getQueryData(["meetings"]) as Meeting[] ?? []
            },
            staleTime: Infinity
        }
    )
    const [meetings, setMeetings] = useState<Meeting[]>(data)

    useEffect(() => {
        if (data) setMeetings(data)
    }, [])

    useEffect(() => {
        if (meetings) queryClient.setQueryData(["meetings"], meetings);
    }, [meetings])

    useEffect(() => {
        if (!userLoggedIn) return
        let unsub = () => { }
        try {
            const meetingsRef = query(
                collection(db, "meetings"),
                where("participants", "array-contains", currentUser.id)
            )
            unsub = onSnapshot(meetingsRef, async (meetingsSnap) => {
                if (meetingsSnap.size == 0) {
                    return
                }
                let meetingList: Meeting[] = []
                const cachedMeetings = JSON.parse(JSON.stringify(data))
                meetingsSnap.forEach((doc) => {
                    meetingList.push(doc.data() as Meeting)
                })

                let allMeetings: Meeting[] = data

                meetingList.forEach((meeting, key) => {
                    let checkMeeting = cloneDeep(cachedMeetings[key])
                    if (checkMeeting && checkMeeting.recording) delete checkMeeting.recording
                    if (checkMeeting && checkMeeting.room) delete checkMeeting.room
                    let currentMeeting = meeting
                    if (currentMeeting && currentMeeting.recording) delete currentMeeting.recording
                    if (currentMeeting && currentMeeting.room) delete currentMeeting.room
                    if (JSON.stringify(currentMeeting) == JSON.stringify(checkMeeting)) {
                        return
                    }
                    allMeetings[key] = { ...meeting, recording: (cachedMeetings[key] && cachedMeetings[key].recording) || [], room: (cachedMeetings[key] && cachedMeetings[key].room) || [] }
                })

                setMeetings(allMeetings)

                meetingList.forEach((meeting) => {
                    if (!meeting) return
                    const meetingRecordingQuery = query(
                        collection(db, "meetings", meeting.id, "recording")
                    );
                    // recording subcollections
                    onSnapshot(meetingRecordingQuery, (meetingSnap) => {
                        let allMeetingsRecording: MeetingRecording[] = []
                        meetingSnap.forEach((meetingDoc) => {
                            allMeetingsRecording.push({ ...meetingDoc.data() as MeetingRecording, id: meetingDoc.id })
                        })
                        setMeetings((prev) => {
                            const index = prev.findIndex((item) => item.id === meeting.id);
                            if (index !== -1) {
                                return prev.map((item, i) =>
                                    i === index ? { ...item, recording: allMeetingsRecording } : item
                                );
                            } else {
                                return [...prev, { ...meeting, recording: allMeetingsRecording }];
                            }
                        })
                    })
                    const meetingRoomQuery = query(
                        collection(db, "meetings", meeting.id, "room")
                    );
                    // room subcollections
                    onSnapshot(meetingRoomQuery, (meetingSnap) => {
                        let allMeetingsRecording: MeetingRoom[] = []
                        meetingSnap.forEach((meetingDoc) => {
                            allMeetingsRecording.push({ ...meetingDoc.data() as MeetingRoom, id: meetingDoc.id })
                        })
                        setMeetings((prev) => {
                            const index = prev.findIndex((item) => item.id === meeting.id);
                            if (index !== -1) {
                                return prev.map((item, i) =>
                                    i === index ? { ...item, room: allMeetingsRecording } : item
                                );
                            } else {
                                return [...prev, { ...meeting, room: allMeetingsRecording }];
                            }
                        })
                    })
                })
            })
        } catch (error: any) {
            console.log(error) //when have an error in firebase (for example invalid id)
        }
        return unsub
    }, [currentUser.id, userLoggedIn])

    const value = {
        meetings
    }

    return (
        <MeetingsContext.Provider value={value}>
            {children}
        </MeetingsContext.Provider>
    )
}