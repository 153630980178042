'use client'

import { db } from "@/connectors/firebase";
import { Commitment, Deal, User } from "@/interfaces/firebase";
import { collection, doc, getDoc, onSnapshot, query } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useAuth from "@/hooks/useAuth";
import { cloneDeep } from "lodash";

type CommitmentWithUser = Commitment & { userData: User }

const DealsContext = React.createContext({
    deals: [] as Deal[],
});

export function useDeals() {
    return useContext(DealsContext);
}

export function DealsProvider({ children }: { children: React.ReactNode }) {
    const queryClient = useQueryClient()
    const { userLoggedIn } = useAuth()
    const { data } = useQuery(
        {
            queryKey: ["deals"],
            initialData: () => {
                return queryClient.getQueryData(["deals"]) as Deal[] ?? []
            },
            staleTime: Infinity
        }
    )
    const [deals, setDeals] = useState<Deal[]>(data)

    useEffect(() => {
        if (data) setDeals(data)
    }, [])

    useEffect(() => {
        if (deals) queryClient.setQueryData(["deals"], deals);
    }, [deals])

    useEffect(() => {
        if (!userLoggedIn) return
        let unsub = () => { }
        try {
            const dealRef = query(collection(db, "deals"))
            unsub = onSnapshot(dealRef, async (querySnapshot) => {
                if (querySnapshot.size == 0) return
                const cachedDeals = JSON.parse(JSON.stringify(data))
                let dealList: Deal[] = []
                for (const snap of querySnapshot.docs) {
                    const dealResult = snap.data() as Deal
                    if (dealResult) {
                        dealList.push(dealResult)
                    }
                }

                let allDeals: Deal[] = []

                dealList.forEach((deal, key) => {
                    let checkCDeals = cloneDeep(cachedDeals[key])
                    if (checkCDeals && checkCDeals.commitments) checkCDeals.commitments = []
                    let currentDeal = deal
                    if (currentDeal && currentDeal.commitments) currentDeal.commitments = []
                    if (JSON.stringify(currentDeal) == JSON.stringify(checkCDeals)) {
                        return
                    }
                    allDeals[key] = { ...deal, commitments: (cachedDeals[key] && cachedDeals[key].commitments) || [] }
                })

                if (allDeals.length > 0) {
                    setDeals(allDeals)
                }

                dealList.forEach((deal) => {
                    const commitmetsRef = query(
                        collection(db, "deals", deal.id, "commitments")
                    )
                    onSnapshot(commitmetsRef, async (querySnapshot) => {
                        let allCommitment: CommitmentWithUser[] = [];
                        for (const commitmetSnap of querySnapshot.docs) {
                            const commitment = commitmetSnap.data() as Commitment
                            const userRef = doc(db, "users", commitment.user);
                            const userSnap = await getDoc(userRef)
                            const user = userSnap.data() as User
                            allCommitment.push({ ...commitment, userData: user });
                        }
                        const sortedCommitmets = allCommitment.sort(
                            (a, b) => a.created.seconds - b.created.seconds
                        );
                        if (sortedCommitmets.length > 0) {
                            setDeals((prev) => {
                                const index = prev.findIndex((item) => item.id === deal.id);
                                if (index !== -1) {
                                    const prevCommitments = prev[index].commitments || [];
                                    const hasChanged = JSON.stringify(prevCommitments) !== JSON.stringify(sortedCommitmets);

                                    if (hasChanged) {
                                        return prev.map((item, i) =>
                                            i === index ? { ...item, commitments: sortedCommitmets } : item
                                        );
                                    }
                                }
                                return [...prev, { ...deal, commitments: sortedCommitmets }];
                            });
                        }
                    })
                })
            })
        } catch (error: any) {
            console.log(error) //when have an error in firebase (for example invalid id)
        }
        return unsub
    }, [userLoggedIn])

    const value = {
        deals
    }

    return (
        <DealsContext.Provider value={value}>
            {children}
        </DealsContext.Provider>
    )
}