"use client";

import React from 'react';
import { AnimatePresence, motion } from "framer-motion";
import { useNotifications } from '@/contexts/notifyContext';
import { useRouter } from 'next/navigation';

export default function Notifications() {
  const { notifications, removeNotification } = useNotifications();
  const router = useRouter()

  const goToPage = (path: string, id: string) => {
    removeNotification(id);
    if (!path) return
    router.push(path)
  };

  const notificationColor = {
    success: {
      bg: "bg-[#d4edda]",
      text: "text-[#155724]",
      line: "bg-[#d4edda]"
    },
    error: {
      bg: "bg-[#f8d7da]",
      text: "text-[#721c24]",
      line: "bg-[#721c24]"
    },
    alert: {
      bg: "bg-[#d1ecf1]",
      text: "text-[#0c5460]",
      line: "bg-[#0c5460]"
    },
    warning: {
      bg: "bg-[#fff3cd]",
      text: "text-[#856404]",
      line: "bg-[#856404]"
    }
  }

  return (
    <div className='absolute top-0 w-full flex flex-col space-y-3 items-center z-50 mt-1 md:px-0 px-3'>
      <AnimatePresence>
        {
          notifications.map((m) => {
            console.log(m)
            return (
              <motion.div
                initial={{ opacity: 0, y: '-100%' }}
                animate={{ opacity: 1, y: 0, transition: { duration: 0.3 } }}
                exit={{ opacity: 0, y: '-100%', transition: { duration: 0.2 } }}
                className={`md:max-w-80 w-full ${notificationColor[m.type!].bg} min-h-7 rounded flex flex-col`}
                key={m.id}
                drag="y"
                dragConstraints={{ top: 0, bottom: 0 }}
                onDragEnd={(event, info) => {
                  if (info.offset.y < 25) {
                    removeNotification(m.id);
                  }
                }}
                onClick={() => m.action ? goToPage(m.action, m.id) : null}
              >
                <div className="flex flex-row justify-start items-center px-2 py-3 space-x-3">
                  {m.image ? (
                    <div className="flex items-center w-[70px] h-[70px]">
                      <div className="w-[70px] h-[70px] relative overflow-hidden">
                        <img
                          src={m.image}
                          alt="notificationImage"
                          className="rounded-full object-cover w-[70px] h-[70px]"
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className={`flex flex-col justify-center h-full ${m.image ? "w-[calc(100%_-_82px)]" : "w-full"}`}>
                    <p className={`line-clamp-1 ${notificationColor[m.type!].text}`}>{m.title}</p>
                    <p className={`${m.fulltext ? "" : "line-clamp-1"} ${notificationColor[m.type!].text}`}>{m.body}</p>
                  </div>
                </div>
                <div className="flex flex-row justify-center items-center mb-2">
                  <span className={`${notificationColor[m.type!].line} rounded-full w-20 h-1`}></span>
                </div>
              </motion.div>
            )
          })
        }
      </AnimatePresence>
    </div>
  )
}