"use client";

import "./globals.css";
import dynamic from 'next/dynamic';
import { Inter } from "next/font/google";
import { AuthProvider } from "@/contexts/authContext";
import Notifications from "@/components/Notification";
import { NotificationProvider } from "@/contexts/notifyContext";
import { TranslateProvider } from "@/contexts/translateContext";
import { FontSizeProvider } from "@/contexts/fontSizeContext";
import { TutorialProvider } from "@/contexts/TutorialContext";
import { TanStackProvider } from "@/contexts/tanStackContext";
import { MessagesProvider } from "@/contexts/messagesContext";
import { ConnectionsProvider } from "@/contexts/connectionsContext";
import { EventsProvider } from "@/contexts/eventsContext";
import { MeetingsProvider } from "@/contexts/meetingsContext";
import { DealsProvider } from "@/contexts/dealsContext";
import { LeadsProvider } from "@/contexts/LeadsContext";
import { NeedsProvider } from "@/contexts/needsContext";
import { AllNotificationProvider } from "@/contexts/allNotificationsContext";
import { SpeedNetworkProvider } from "@/contexts/speedNetworkContext";
import { NotificationBannerProvider } from "@/contexts/notificationBannerContext";
import ErrorBoundary from "@/components/ErrorBoundary";
// import { TransitionRouter } from "next-transition-router";
import { gsap } from "gsap";

const inter = Inter({ subsets: ["latin"] });

// import type { Metadata } from "next";
// import meta from "../../public/version.json";
// export const metadata: Metadata = {
//   title: "YPO",
//   description: "YPO",
//   viewport: {
//     minimumScale: 1,
//     initialScale: 1,
//     width: "device",
//     userScalable: false,
//     viewportFit: "cover",
//     maximumScale: 1
//   }
// };

const Layout = dynamic(() => import('@/components/Layout').then(mod => mod.default), {
  ssr: false
});

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {

  return (
    <html lang="en" className="h-full">
      <head>

        <meta
          name='viewport'
          content='minimum-scale=1, maximum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover'
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
        <meta name="application-name" content="YPO" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="YPO" />
        <meta name="description" content="The global leadership community" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-config" content="/icons/browserconfig.xml" />
        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#FFFFFF" />

        <link rel="apple-touch-icon" href="/icon512_maskable.png" />

        <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="mask-icon" href="/icon512_maskable.png" color="#5bbad5" />
        <link rel="shortcut icon" href="/icon512_maskable.png" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://ypo.org" />
        <meta name="twitter:title" content="YPO" />
        <meta name="twitter:description" content="The global leadership community" />
        <meta name="twitter:image" content="https://ok7static.oktacdn.com/fs/bco/1/fs0tfcie9n48U4hfL357" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="YPO" />
        <meta property="og:description" content="The global leadership community" />
        <meta property="og:site_name" content="YPO" />
        <meta property="og:url" content="https://ypo.org" />
        <meta property="og:image" content="https://ypo.com/icons/apple-touch-icon.png" />

      </head>
      <body className={`scrollbar-thin touch-none ${inter.className} h-full`}> {/*  class touch-none remove zoom when clicking on input with text smaller than 16px */}
        <TanStackProvider>
          {/* <TransitionRouter
            enter={(next) => {
              gsap.set(".main", { autoAlpha: 0, xPercent: -100 });
              const tween = gsap
                .timeline({
                  onComplete: next,
                })
                .to(".main", { autoAlpha: 1, xPercent: 0, duration: 0.3 })
                .play();
              return () => tween.kill();
            }}
            leave={(next) => {
              const tween = gsap
                .timeline({
                  onComplete: next,
                })
                .to(".main", { xPercent: 100, autoAlpha: 0, duration: 0.3 })
                .play();
              return () => tween.kill();
            }}
          > */}
          <AuthProvider>
            <ErrorBoundary>
              <NotificationProvider>
                <FontSizeProvider>
                  <TranslateProvider>
                    <TutorialProvider>
                      <MessagesProvider>
                        <ConnectionsProvider>
                          <SpeedNetworkProvider>
                            <EventsProvider>
                              <MeetingsProvider>
                                <DealsProvider>
                                  <NotificationBannerProvider>
                                    <LeadsProvider>
                                      <NeedsProvider>
                                        <AllNotificationProvider>
                                          <Layout>
                                            <Notifications />
                                            {children}
                                          </Layout>
                                        </AllNotificationProvider>
                                      </NeedsProvider>
                                    </LeadsProvider>
                                  </NotificationBannerProvider>
                                </DealsProvider>
                              </MeetingsProvider>
                            </EventsProvider>
                          </SpeedNetworkProvider>
                        </ConnectionsProvider>
                      </MessagesProvider>
                    </TutorialProvider>
                  </TranslateProvider>
                </FontSizeProvider>
              </NotificationProvider>
            </ErrorBoundary>
          </AuthProvider>
          {/* </TransitionRouter> */}
        </TanStackProvider>
      </body>
    </html >
  );
}
