'use client'

import React, { useContext, useEffect, useState } from "react";
import { QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'

const TanStackContext = React.createContext({});

export function useTanStack() {
    return useContext(TanStackContext);
}

export function TanStackProvider({ children }: { children: React.ReactNode }) {
    const [isClient, setIsClient] = useState(false);
    const [persister, setPersister] = useState<any>(null);
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                networkMode: "offlineFirst",
                retryOnMount: false,
                staleTime: Infinity
            }
        }
    })

    useEffect(() => {
        setIsClient(true);
        setPersister(createSyncStoragePersister({
            storage: window.localStorage,
        }));
    }, []);

    const value = {}

    if (!isClient || !persister) {
        return null;
    }

    return (
        <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
            <TanStackContext.Provider value={value}>
                {children}
            </TanStackContext.Provider>
        </PersistQueryClientProvider>
    )
}