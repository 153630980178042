'use client'

import { User } from "@/interfaces/firebase";
import { useRouter, useSearchParams } from "next/navigation";
import React, { useEffect } from "react";
import { useMessages } from "./messagesContext";
import { useVersionManager } from "@/hooks/useVersionManager";
import useAuth from "@/hooks/useAuth"

const AuthContext = React.createContext({
	currentUser: {} as User,
	userLoggedIn: false,
	loading: true,
	isRecording: false,
	onMenu: false,
	isPremiumMember: false,
	requestOTP: false,
	setOnMenu: (status: boolean) => { },
	setUser: (user: User) => { },
	refreshUser: async () => { },
	setRequestOTP: (request: boolean) => { },
	setRecording: (recording: boolean) => { },
});

export function AuthProvider({ children }: { children: React.ReactNode }) {

	const router = useRouter()
	const searchParams = useSearchParams();

	useVersionManager();

	const { currentUser, initialized, isPremiumMember, isRecording, isRequestingOTP, refreshUser, setIsRecording, setIsRequestingOTP, setUser, user, userLoggedIn } = useAuth();
	// const { setIsLoadingMessages } = useMessages()

	useEffect(() => {
		console.log({
			userLoggedIn, initialized
		})

		if (userLoggedIn === false && initialized === true) {
			const params = new URLSearchParams(searchParams.toString())
			const scan = params.get("isQrcode")
			if (scan) {
				setTimeout(() => {
					router.push(`/qr-redirect`)
				}, 1000);
			}
			router.push(`/authentication`)
		}

		// if (userLoggedIn === true) {
		// 	setIsLoadingMessages(true)
		// }
	}, [userLoggedIn, initialized])

	const value = {
		onMenu: false,
		loading: !initialized,
		requestOTP: isRequestingOTP,
		isRecording,
		currentUser: currentUser!,
		userLoggedIn,
		isPremiumMember,
		setUser,
		setOnMenu: () => { },
		refreshUser,
		setRecording: setIsRecording,
		setRequestOTP: setIsRequestingOTP
	}

	return (
		<AuthContext.Provider value={value}>
			{value.loading === false && children}
		</AuthContext.Provider>
	)
}